.coupon-list {
  .coupons {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    align-items: stretch;
  }
  .coupon {
    flex-grow: 2;
    flex-basis: 47%;
    max-width: 47%;
    position: relative;

    @media #{$breakpoint-above-tablet} {
      flex-basis: 23%;
      max-width: 23%;
    }

    &--image {
      aspect-ratio: 4 / 3;
      margin-bottom: -72px;
      position: relative;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &--info {
      position: relative;
      z-index: 1;
      color: $Brand-Black;
      border-radius: 0 0 8px 8px;
      padding: 80px 24px 24px;
    }

    &--background {
      background-color: $white;
      border-radius: 0 0 8px 8px;
      padding: 0 24px 24px;
      box-shadow: $Brand-Red 6px 6px 0 0;
      height: calc(100% - 125px);
      width: 100%;
      z-index: 0;
      position: absolute;
      bottom: 0;
    }

    &--title {
      font-family: $National2Condensed;
      text-transform: uppercase;
    }

    &--strikethrough {
      color: $Brand-Red;
    }
  }
}
