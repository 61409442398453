.flexible-component {
  &--header-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 48px 0 24px 0;
  }
  &--header {
    font-family: $National2Condensed;
    text-transform: uppercase;
  }
}
